import IMG_headshot from '../static/headshot.jpg'

const IMAGES = {
  // Headshot
  me: {
    avatar: IMG_headshot,
    img: null,
  },

  // WORK
  harvard: {
    avatar: 'https://imgur.com/Xh2jEZc',
    img: 'https://imgur.com/WP8JRmr',
  },
  facebook: {
    avatar: 'https://imgur.com/GrVmzFx',
    img: 'https://imgur.com/rgcOD0v',
  },
  google: {
    avatar: 'https://imgur.com/bgFhqKr',
    img: 'https://imgur.com/riiCvyR',
  },
  oxford: {
    avatar: 'https://imgur.com/dbzyArx',
    img: null,
  },

  // COMMUNITY
  t4k: {
    avatar: 'https://imgur.com/YNPZFXh',
    img: 'https://imgur.com/KkA8ONB',
  },
  travis: {
    avatar: 'https://imgur.com/NfZA9uQ',
    img: 'https://imgur.com/wTrYnh2',
  },
  outyouth: {
    avatar: 'https://imgur.com/OmkrDvo',
    img: null,
  },
  measure: {
    avatar: 'https://imgur.com/EACJKaZ',
    img: 'https://imgur.com/JRaopOr',
  },
  dell: {
    avatar: 'https://imgur.com/sQEEpqC',
    img: null,
  },
  advance: {
    avatar: 'https://imgur.com/tQMBWnI',
    img: 'https://imgur.com/nGSBGDG',
  },

  // APPS
  txballottracker: {
    avatar: 'https://imgur.com/z5gi0PO',
    img: 'https://imgur.com/4EtN0Lv',
  },
  uplift: {
    avatar: 'https://imgur.com/ApmriBO',
    img: 'https://imgur.com/hSzBIan',
  },
  mail: {
    avatar: 'https://imgur.com/32zmvJM',
    img: 'https://imgur.com/EkGBBZh',
  },
  satya: {
    avatar: 'https://imgur.com/x26oRj5',
    img: 'https://imgur.com/VGXw92d',
  },
  degreeaudit: {
    avatar: null,
    img: 'https://imgur.com/fZrdWnQ',
  },
  carpediem: {
    avatar: null,
    img: 'https://imgur.com/68oQ5tw',
  },
  robot: {
    avatar: 'https://imgur.com/UF2iuIS',
    img: 'https://imgur.com/DGIyUZf',
  },

  // RESUMES
  v0: {
    img: 'https://imgur.com/9ARvVEE',
  },
  v1: {
    img: 'https://imgur.com/ogMZOpe',
  },
  v2: {
    img: 'https://imgur.com/HjYWpzl',
  },
  v3: {
    img: 'https://imgur.com/McHEAJd',
  },
  v4: {
    img: 'https://imgur.com/d5NSHUF',
  },
  v5: {
    img: 'https://imgur.com/6PHSPcO',
  },
  v6: {
    img: 'https://imgur.com/UPLJDo5',
  },
  latest: {
    img: 'https://imgur.com/Pus49Wm',
  },
}

export default IMAGES
