import _ from 'lodash'
import React from 'react'

import {
  AppBar,
  Avatar,
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import headerStyle from '../styles/header'
import Icon from './icon'
import Logger from '../utils/logger'
import menu from '../data/menu'
import SocialMedia from './social_media'
import Window from '../utils/window'

import IMAGES from '../data/images'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(_theme => ({
  root: {},
}))(MenuItem)

export default function Header() {
  const classes = headerStyle()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = e => {
    Logger.log('explore')
    setAnchorEl(e.currentTarget)
  }
  const handleClose = () => setAnchorEl(null)

  return (
    <div className={classes.root}>
      <AppBar className={classes.bar}>
        <Toolbar>
          <Grid container className={classes.container}>
            {/** LOGO */}
            <Grid item className={classes.title}>
              <Tooltip title="Explore my site">
                <Button
                  startIcon={
                    <Avatar
                      alt="My pretty face"
                      src={IMAGES.me.avatar}
                      className={classes.avatar}
                    />
                  }
                  endIcon={<Icon src="arrow" />}
                  onClick={handleClick}
                >
                  <Typography>Shiva</Typography>
                </Button>
              </Tooltip>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {_.map(menu, (item, idx) => (
                  <StyledMenuItem
                    key={idx}
                    onClick={() => {
                      if (item.disabled) Window.WIP()
                      else item.onClick()
                      handleClose()
                    }}
                  >
                    <ListItemIcon>
                      <Icon src={item.icon} color={item.color} />
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </StyledMenuItem>
                ))}
              </StyledMenu>
            </Grid>

            {/** SOCIAL MEDIA */}
            <SocialMedia />
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}

Header.propTypes = {}
Header.defaultProps = {}
