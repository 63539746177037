import React from 'react'
import moment from 'moment'

import Grid from '@material-ui/core/Grid'

import SocialMedia from './social_media'

import footerStyle from '../styles/footer'

export default function Footer() {
  const classes = footerStyle()

  return (
    <div>
      <Grid container className={classes.container}>
        <Grid item>
          <SocialMedia invertBackground={true} />
        </Grid>
        <Grid item className={classes.copyright}>
          &#169; {moment().year()} by Shiva Velingker
          <br />
          Yes, I built this myself {String.fromCodePoint('0x1F605')}
        </Grid>
      </Grid>
    </div>
  )
}

Footer.propTypes = {}
Footer.defaultProps = {}
