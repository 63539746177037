import { makeStyles } from '@material-ui/core/styles'

import { FONTS } from '../utils/core'
import Colors from '../utils/colors'

const headerStyle = makeStyles(theme => ({
  root: {
    flex: 0,
  },
  bar: {
    color: 'black',
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  container: {
    alignItems: 'center',
    flexWrap: 'initial',
  },
  avatar: {
    marginRight: '15px',
    height: theme.spacing(7.5),
    width: theme.spacing(7.5),
  },
  title: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    paddingTop: '0.5em',
    paddingBottom: '1em',
    '& a': {
      textDecoration: 'none',
    },
    '& p': {
      fontSize: '2em',
      fontFamily: FONTS.primary,
      userSelect: 'none',
      color: 'transparent',
      textFillColor: 'transparent',
      backgroundClip: 'text',
      '-webkit-background-clip': 'text',
      background: Colors.gradient('main'),
      wordWrap: 'none',
    },
  },
  buttonContainer: {
    marginRight: theme.spacing(2),
    display: 'flex',
    flexFlow: 'row',
  },
}))

export default headerStyle
