/*
	eslint no-console: ["error", { allow: ["log"] }]
*/
import _ from 'lodash'

export default class Logger {
  /**
   * Logs an action happened + redirects if necessary
   * @param {string} event
   * @param {string} redirect
   */
  static log(event, redirect) {
    if (_.isFunction(window.sa_event)) {
      window.sa_event(event)
    } else {
      console.log(`Could not log: ${event}`)
    }

    if (redirect) {
      window.open(redirect, '_blank')
    }
  }
}
