import Window from '../utils/window'

const menu = [
  {
    name: 'Portfolio',
    icon: 'lightbulb',
    color: 'amber',
    onClick: () => Window.navigate('../portfolio'),
  },
  {
    name: 'My Resume Template',
    icon: 'resume',
    color: 'pink',
    onClick: () => Window.navigate('../resumes'),
  },
  {
    name: 'Get your Resume Reviewed',
    icon: 'review',
    color: 'blue',
    onClick: () => Window.navigate('../review'),
  },
]

export default menu
