import { makeStyles } from '@material-ui/core/styles'

import Colors from '../utils/colors'
import { FONTS } from '../utils/core'

const footerStyle = makeStyles(() => ({
  container: {
    display: 'flex',
    padding: '10px',
    height: '4em',
    color: Colors.get('white'),
    backgroundColor: Colors.get('grey', 800),
    alignItems: 'center',
    fontFamily: FONTS.primary,
    fontWeight: 300,
    overflow: 'hidden',
  },
  copyright: {
    flex: 1,
    textAlign: 'right',
  },
}))

export default footerStyle
