import _ from 'lodash'

import { queue } from './core'
import Window from './window'

const BACKEND_URL =
  process.env.GATSBY_BACKEND === 'local'
    ? 'http://localhost:5000'
    : 'https://shivalicious.herokuapp.com'
const API_URL = `${BACKEND_URL}/`

export default class REST {
  static abort() {
    _.forEach(queue, (request, key) => {
      request.abort()
      delete queue[key]
    })
  }

  /**
        TODO check for Internet connection
    */
  static post(endpoint, data = {}) {
    const controller = new AbortController()
    const key = _.uniqueId()
    queue[key] = controller

    data.debug = process.env.GATSBY_BACKEND === 'local' ? true : false
    if (window.location.hash === '#ignore') {
      // Allow me to visit website w/o logging
      data.debug = true
    }

    return fetch(API_URL + endpoint, {
      method: 'post',
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
      signal: controller.signal,
    })
      .then(response => {
        delete queue[key]
        if (response.status !== 200) throw response

        return response.json()
      })
      .catch(error => {
        delete queue[key]
        Window.alert(
          `Database failure: ${error.status} (${error.statusText})`,
          {
            variant: 'error',
          }
        )
        throw error
      })
  }
}
