import React from 'react'
import Helmet from 'react-helmet'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import Footer from '../components/footer'
import Header from '../components/header'
import pageStyle from '../styles/page'
import REST from '../utils/rest'
import Window from '../utils/window'

function Page({ content, customCSS, enqueueSnackbar }) {
  React.useEffect(() => {
    Window.alertFn = enqueueSnackbar

    // Log traffic
    if (!Window.get('sa_event')) {
      console.log('Loaded SA')
      let script = document.createElement('script')
      script.src = 'https://api.shivalicious.com/latest.js'
      script.async = true
      script.defer = true
      document.body.append(script)
    }

    // Wakeup database
    REST.post('resumes/wakeup')
  }, [enqueueSnackbar])

  const classes = pageStyle()

  return (
    <div className={classes.container}>
      <Helmet>
        <style>{customCSS}</style>
      </Helmet>

      {/** HEADER */}
      <Header />

      {/** PAGE */}
      <div className={classes.content}>{content}</div>

      {/** FOOTER */}
      <Footer />
    </div>
  )
}

Page.propTypes = {
  content: PropTypes.element,
  customCSS: PropTypes.string,
  enqueueSnackbar: PropTypes.func.isRequired,
}

Page.defaultProps = {
  content: null,
  customCSS: null,
}

export default withSnackbar(Page)
