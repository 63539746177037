import { makeStyles } from '@material-ui/core/styles'
import Colors from '../utils/colors'

const pageStyle = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  content: {
    marginTop: '89px',
    padding: theme.spacing(1),
    flex: 1,
    backgroundColor: Colors.get('grey', 100),
  },
}))

export default pageStyle
