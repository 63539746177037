import React from 'react'
import PropTypes from 'prop-types'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import Colors from '../utils/colors'
import Icon from './icon'

export default function CustomIconButton({ color, onClick, src, style, tooltip }) {
  function renderIconButton() {
    return (
      <IconButton onClick={onClick} style={style}>
        <Icon src={src} color={color} />
      </IconButton>
    )
  }

  return tooltip ? (
    <Tooltip title={tooltip}>{renderIconButton()}</Tooltip>
  ) : (
    renderIconButton()
  )
}

CustomIconButton.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
  tooltip: PropTypes.string,
}

CustomIconButton.defaultProps = {
  color: Colors.get('blueGrey', 700),
  onClick: () => {},
  style: null,
  tooltip: null,
}
