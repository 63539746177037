import _ from 'lodash'

import amber from '@material-ui/core/colors/amber'
import blue from '@material-ui/core/colors/blue'
import lightBlue from '@material-ui/core/colors/lightBlue'
import brown from '@material-ui/core/colors/brown'
import cyan from '@material-ui/core/colors/cyan'
import green from '@material-ui/core/colors/green'
import lightGreen from '@material-ui/core/colors/lightGreen'
import grey from '@material-ui/core/colors/grey'
import blueGrey from '@material-ui/core/colors/blueGrey'
import indigo from '@material-ui/core/colors/indigo'
import lime from '@material-ui/core/colors/lime'
import orange from '@material-ui/core/colors/orange'
import deepOrange from '@material-ui/core/colors/deepOrange'
import pink from '@material-ui/core/colors/pink'
import purple from '@material-ui/core/colors/purple'
import deepPurple from '@material-ui/core/colors/deepPurple'
import red from '@material-ui/core/colors/red'
import teal from '@material-ui/core/colors/teal'
import yellow from '@material-ui/core/colors/yellow'

const gradients = {
  main: 'linear-gradient(90deg, #000000 0%, #7F8C8D 100%)',
  instagram:
    'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)',
}

const hues = {
  amber,
  blue,
  lightBlue,
  brown,
  cyan,
  green,
  lightGreen,
  grey,
  blueGrey,
  indigo,
  lime,
  orange,
  deepOrange,
  pink,
  purple,
  deepPurple,
  red,
  teal,
  yellow,
}

export default class Colors {
  static changeShade(hexColor, newShade = 500) {
    const currentHue = _.findKey(hues, hue => {
      return _.indexOf(_.values(hue), hexColor) > -1
    })

    return this.get(currentHue, newShade)
  }

  static get(hue, shade = 500) {
    if (hue === 'random') {
      const allColors = _.keys(hues)
      hue = allColors[_.random(allColors.length - 1)]
    } else if (hue === 'white') return '#FFFFFF'
    else if (hue === 'black') return '#000000'
    else if (_.has(hues, hue) === false) return null
    return hues[hue][shade]
  }

  static gradient(name) {
    return gradients[name]
  }
}
