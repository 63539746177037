/**
    @public Global Constants 
*/
export const isDev =
  typeof process !== 'undefined' &&
  typeof process.env !== 'undefined' &&
  process.env.NODE_ENV === 'development'
export const isProd = !isDev
export const isTestMode =
  typeof process !== 'undefined' &&
  typeof process.env !== 'undefined' &&
  process.env.NODE_ENV === 'test'

export const FONTS = {
  primary: 'Montserrat, sans-serif',
}

export const queue = {}

export const URLs = {
  Instagram: 'https://www.instagram.com/shivalicious/',
  LinkedIn: 'https://www.linkedin.com/in/shiva-velingker/',
  YouTube:
    'https://www.youtube.com/channel/UC2Cv3rP6L3Mg9U2pAFKXeQA?sub_confirmation=1',
  resume_email: 'resumes@shivalicious.com',
}

export const img_embed = url => {
  if (url.includes('imgur')) {
    const ID = url.match(/https:\/\/imgur\.com\/([-\w]+)/)[1]
    return `https://i.imgur.com/${ID}.png`
  }
  return url
}
