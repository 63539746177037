import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { URLs } from '../utils/core'
import Colors from '../utils/colors'
import IconButton from '../components/icon_button'
import Logger from '../utils/logger'

const useStyle = makeStyles(theme => ({
  container: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    flexFlow: 'row',
  },
}))

export default function SocialMedia({ invertBackground }) {
  const classes = useStyle()

  return (
    <Grid item className={classes.container}>
      <IconButton
        src="instagram"
        tooltip="Instagram @shivalicious"
        color={
          invertBackground ? Colors.get('white') : Colors.get('pink', 'A400')
        }
        style={
          invertBackground ? { background: Colors.gradient('instagram') } : null
        }
        onClick={() => Logger.log('Instagram', URLs.Instagram)}
      />
      <IconButton
        src="youtube"
        tooltip="YouTube @shivalicious"
        color={Colors.get('red')}
        style={
          invertBackground
            ? {
                backgroundColor: 'white',
                marginLeft: '5px',
                marginRight: '5px',
              }
            : null
        }
        onClick={() => Logger.log('YouTube', URLs.YouTube)}
      />
      <IconButton
        src="linkedin"
        tooltip="LinkedIn"
        color="#2867b2"
        style={invertBackground ? { backgroundColor: 'white' } : null}
        onClick={() => Logger.log('LinkedIn', URLs.LinkedIn)}
      />
    </Grid>
  )
}

SocialMedia.propTypes = {
  invertBackground: PropTypes.bool,
}
SocialMedia.defaultProps = {
  invertBackground: false,
}
