import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

import {
  Build,
  Description,
  EmojiObjects,
  GetApp,
  Instagram,
  KeyboardArrowDown,
  Launch,
  LinkedIn,
  RateReview,
  YouTube,
} from '@material-ui/icons'

import Colors from '../utils/colors'

const icons = {
  arrow: KeyboardArrowDown,
  build: Build,
  download: GetApp,
  launch: Launch,
  lightbulb: EmojiObjects,
  resume: Description,
  review: RateReview,

  instagram: Instagram,
  youtube: YouTube,
  linkedin: LinkedIn,
}

export default function CustomIcon({ color, src, style, tooltip }) {
  if (!_.has(icons, src)) throw Error(`Icon not accounted for: ${src}`)

  // By hue or explicit hex
  const parsedColor = Colors.get(color) || color

  const icon = React.createElement(icons[src], {
    style: _.assign(style || {}, {
      color: parsedColor,
    }),
  })

  if (tooltip) return <Tooltip title={tooltip}>{icon}</Tooltip>

  return icon
}

CustomIcon.propTypes = {
  color: PropTypes.string,
  src: PropTypes.string.isRequired,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  tooltip: PropTypes.string,
}

CustomIcon.defaultProps = {
  color: 'blueGrey',
  style: null,
  tooltip: null,
}
